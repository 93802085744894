@import url('https://fonts.googleapis.com/css2?family=Mukta:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

#about-content{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

#about-content #img-id{
    padding-right: 20px;
    width: 600px;
    height: auto;
    position: relative;
 
}
.contant p {
    font-family: 'Mukta', sans-serif;
    text-align: justify;
    margin-top: 18px;
}
.about-title{
    background:  linear-gradient(to left, #4568dc, #b06ab3);
    margin-top: 110px;
    font-size: 5rem;
    padding: 30px;
   /* border-radius:5px; */
   font-family: 'Bebas Neue', cursive !important;
    
   color: #f1f1f1;
}
.imagecolum img {
    border-radius: 5px;
    
}