@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.service-text-h{
	font-family: 'Bebas Neue', cursive;
	background: linear-gradient(to left, #4568dc, #b06ab3);
	color: #f1f1f1;
	padding: 30px;
	font-size: 5rem;
	gap: 10px;
	align-items: center;
	letter-spacing: 4px;
	/* margin-top: 110px; */
  }
  .service-text-b{
	font-family: 'Bebas Neue', cursive;
	background: linear-gradient(to left, #4568dc, #b06ab3);
	color: #f1f1f1;
	padding: 30px;
	font-size: 5rem;
	gap: 10px;
	align-items: center;
	letter-spacing: 4px;
	margin-top: 110px;
  }
  .small-tag{
	font-family: 'Bebas Neue', cursive;
	color: #f1f1f1;
	letter-spacing: 5px;
  }
  .solution-text-h{
	font-family: 'Bebas Neue', cursive;
	
	color: #f1f1f1;

	font-size: 5rem;
	gap: 10px;
	align-items: center;
	letter-spacing: 5px;
  }

  @media screen and (max-width: 768px) {
	.service-text-h{
		font-size: 25px;
	}
	.solution-text-h{
		font-size: 3rem !important;
		padding: 20px;
	}
	.service-text-b{
		font-size: 25px;
	}
	.solution-text-b{
		font-size: 3rem !important;
		padding: 20px;
	}
  }