.hypno-img{
   float: left;
}
.descSection p {
   text-align: justify;
}
.faqs{
   background: linear-gradient(to left, #4568dc, #b06ab3);
	color: #f1f1f1;
	padding: 30px;
	font-size: 3rem;
   font-weight: bold;
   /* margin-top: -3rem; */
	align-items: center;
	letter-spacing: 5px;
   margin-top: 110px;
	
}

.faqimg{
   height: 400px;
   margin-bottom: 1rem;
   width: 600px;
   object-fit: cover;
}

