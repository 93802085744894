@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Yesteryear&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cookie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
h1,
h2,
h3,
h4 {
  /* letter-spacing: 0.1rem; */
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h2 {
  background-blend-mode: lighten;

  opacity: 0.9;
}
h1 {
  padding-left: 10px;
  opacity: 0.9 !important;
}
.section {
  width: 100%;
  margin-top: 2rem;
  /* max-width: 1170px;
  background-color: #f5f5f5; */

  /* padding-top: 2rem; */
  
}

.icon {
  font-size: 3rem;
  color: #540553;
}
.section-center {
  margin: 0 auto;
  /* margin-top: 4rem; */
  width: 80vw;
  height: 390px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  margin-bottom: 50px;
}
article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}
article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}
.person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 2px solid #4568dc;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
article h4 {
  text-transform: uppercase;
  margin-bottom: 0.25rem;
}
.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: #540553;

}
.title {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.title h2 {
  /* font-family: "Yesteryear", cursive; */
  font-family: 'Bebas Neue', cursive !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 3.5rem !important;
  margin-top: 110px;
}
.text{
  font-family: 'Cookie', cursive !important;
  font-size: 16px;
  font-weight: 500;
}

/* .text {
    max-width: 35em;
    margin: 0 auto;
    margin-top: 2rem;
    line-height: 2;
} */
.next,
.prev {
  font-size: 22px !important;
  position: absolute;
  padding: 5px;
  top: 100px;
  transform: translateY(-50%);
  background: linear-gradient(to left, #4568dc, #b06ab3);
  color: #fff;
  /* width: 1.25rem;
    height: 1.25rem; */
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s linear;
}
.next {
  left: 0;
}
.prev {
  right: 0;
}
.appoinment-button {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background-color: rgb(248, 159, 115);
  text-decoration: none;
  animation-delay: 0.6s;
}
