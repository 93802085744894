@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap');
@font-face {
    font-family: uni_neue;
    src: url(UniNeue-Regular.woff);
  }
  @font-face {
    font-family: uni_neue_bold;
    src: url(UniNeue-Bold.woff);
  }

.section-info{
    display: flex;
    align-items: center;
    justify-content: center;
 

}
.small{
    font-size: 24px !important;
}
.welcome{
    padding-left: 0.1rem !important;
    /* padding-bottom: 108px !important; */
}
.single-blog{
    display: flex;
    align-items: center;
    justify-content: center;
}
.blog-margin{
    margin-bottom: 3rem;
    border-bottom: 1px;
}
.hero-title{
    background:  linear-gradient(to left, #4568dc, #b06ab3);
    margin-top: 1.5rem;
    font-size: 3.5rem;
    padding: 45px;
   /* border-radius:5px; */
  

   color: #f1f1f1;
    
}
.appointment-header{
    display: flex;
    background: linear-gradient(to left, #4568dc, #b06ab3);
    align-items: flex-start;
 
    padding: 16px 149px;
    border-bottom: 1px solid #e9ecef;
    
}
.appointment-title{
    font-size: 20px !important;
}
.appointment-title:hover{
   letter-spacing: 0.5px;
   font-weight: 700;
}
.contact-img{
   
    width: auto;
   
    height: 305px;
    
}
.contact-title{
     width: auto; 
     font-family:  uni_neue_bold;
}

.hero-title::before {
    content: "";
    position: fixed;
    display: flex;
    align-content: center;
    left: 0;
    bottom: -10px;
    background:  linear-gradient(to right, #4568dc, #b06ab3) ;
    height: 2px;
    box-sizing: border-box;
    width: 100%;
   
  }
.blog-title{
    /*color: #b97fb8;*/
    font-family: 'Cairo', sans-serif;
    font-size: 1.5rem;
    font-weight: bold;
}
.blog-section{
    display: flex;
    justify-content:flex-start;
    align-items: center;
}
.blog-section h1{
    margin-left: 3rem;
}
.section-info{
    padding-left: 2rem;
    /*padding-bottom: 2rem;*/
}
.send-button{
    background:linear-gradient(to left, #4568dc, #b06ab3) ;
    color: #ffffff;
    border: none;
}
.contact-text{
    padding-left: 100px;
}
.contact-text-phone{
    margin-right: 30px;
}
.fa{
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display,inline-block);
    font-size: 20px;
    margin-bottom: 7px;
    font-style: normal;
    font-variant: normal;
    line-height: 1.5;
    text-rendering: auto;
}
.welcome-text{
    font-family: 'Fira Sans', sans-serif;
    font-size: 1rem;
    margin-right: 2rem;
    text-align: justify;
    text-shadow: #540553;
    font-size: 14.7px;
    font-weight: 500;
}
a{
    text-decoration: none !important;
    color: #1a1819;
}
.str{
    font-size: 30px;
    font-weight: 100;
}
.paragraph{
    
    text-align: justify;
}

.img-fluid{
    margin-top: 25px;
}

@media screen and (max-width: 768px) {
    .section-info{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 2rem;
    }
    .hero-title{
        font-size: 2rem;
        padding: 30px;
    }
    .contact-text{
        padding-left: 13px;
    }
    .contact-text-phone{
        margin-right: 10px;
    }
    .section-info{
        margin-top: 10rem;
    }
    .contact-img{
      
        width: 100%;
        }

    .welcome{
    padding: 0.1rem !important;
    margin: 2px;
}
.appointment-header{
    display: flex;
    background: linear-gradient(to left, #4568dc, #b06ab3);
    align-items: flex-start;
 
    padding: 22px 90px;
    border-bottom: 1px solid #e9ecef;
    
}
.appointment-title{
    font-size: 20px !important;
}
}