@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap');

/* .button {
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 50px;
  transition: 0.5s;
  color: #fff;
  background:  linear-gradient(to left, #4568dc, #b06ab3) ;
  text-decoration: none;
  animation-delay: 0.6s;
} */
.home-button-program{
  background-color: #2e3560 !important;
  font-family: 'Oswald', sans-serif;
  font-size: 4rem;
  font-weight: 100;
  overflow: hidden;
  padding: 1rem 2rem;
  position: relative;
  text-decoration: none;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;
  color: #ffffff !important;
  opacity: 0.9 !important;
}
.home-button-program::after {
  color: #ffffff !important;
    background:linear-gradient(to right, #4568dc, #b06ab3);
    border-radius: 5px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  .home-button-program:hover::after {
    transform: translate(0, 0);
   
  }
  .home-button-program:hover {
  
    color: #ffffff !important;
    
  }
.card-img-top{
  height: 245px;
  transition: all 0.2s ease;
}
.card-img-top:hover{
  cursor: pointer;
transform: translateY(-6px);
box-shadow: 0 1rem 1rem rgba(0,0,0,.15)!important;
}
.card-title{
  font-family: 'Cairo', sans-serif;
  font-size: 17px;
}

/* .card-body{
  background-color: #d4b5d357;
  
} */
/* .inner{
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.inner{
  transition: all 0.3s ease;
}
.inner:hover{
  transform:rotateY(90deg);
  top: 2px;
  box-shadow: linear-gradient(to left, #4568dc, #b06ab3) ;
} */
/* .card-text{
  height: 150px;
} */
.padding{
  padding: 1.8px;
}

@media (max-width: 768px){
  .card{
    margin: 0 0px 20px
  }
  .card-img-top{
    height: auto;
    
  }
}