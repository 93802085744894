.blog-wrap {
	max-width: 700px;
	margin: 0 auto;
	
  }
  .blog-title{
	background-color: linear-gradient(to left, #4568dc, #b06ab3) !important;
  }
  
  .blog-goBack {
	text-decoration: none;
	font-size: 0.8rem;
	color: #a9a9a9;
	font-weight: 500;
	margin-bottom: 2rem;
	display: block;
  }
  .blog-wrap header {

	text-align: center;
  }
  
  .blog-date {
	font-size: 0.8rem;
	color: #a9a9a9;
	font-weight: 500;
  }
  
  .blog-wrap img {
	width: 100%;
  }
  
  .blog-subCategory {
	display: flex;
	justify-content: center;
  }
  .blog-subCategory > div {
	margin: 1rem;
  }
  .blog-desc {
	padding: 1rem;
	margin-top: 1.5rem;
  }
   
