@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.blog-wrap {
	max-width: 1180px;
	margin: 0 auto;
	
  }
  .servicestext{
	font-family: 'Bebas Neue', cursive;
	background: linear-gradient(to left, #4568dc, #b06ab3);
	color: #f1f1f1;
	padding: 30px;
	font-size: 5rem;
	gap: 10px;
	align-items: center;
	letter-spacing: 5px;
	margin-top: 100px;
  }
  
  .blog-goBack {
	text-decoration: none;
	font-size: 0.8rem;
	color: #a9a9a9;
	font-weight: 500;
	margin-bottom: 2rem;
	display: block;
  }
  .blog-wrap header {

	text-align: center;
  }
  
  .blog-date {
	font-size: 0.8rem;
	color: #a9a9a9;
	font-weight: 500;
  }
  
  .blog-wrap img {
	width: 50%;
	float: left;
	margin: 20px;
	
  }
  
  .blog-subCategory {
	display: flex;
	justify-content: center;
  }
  .blog-subCategory > div {
	margin: 1rem;
  }
  .blog-desc {
	
	padding: 1rem;
	margin-top: 1.5rem;
	text-align: justify;
  }
   

  @media screen and (max-width: 768px) {
	.servicestext{
		font-size: 2rem;
	}
	.faqimg{
	
		height: auto !important;
		width: 100% !important;
			;
	}
	p{
		padding: 0 !important;
		margin: 0 !important;
	}
  }