

.modal-content{
    position: relative;
    margin-top: 60px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0
}
.modal-header {
    display: flex;
    background:  linear-gradient(to left, #4568dc, #b06ab3)  ;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 3rem;
    padding: 10px 149px;
    border-bottom: 1px solid #e9ecef;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}
.modal.title{
    margin-bottom: 0;
    line-height: 1.5;
    color: rgb(255, 255, 255) !important;
}
#modalRequestLabel{

    color: #e9ecef;
}
.modal.body{
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem;
    width: 40%;
}
.form-group{
    margin-bottom: 1rem;
}
.row{
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.sticky-left-container{
    padding: 0px;
    margin: 0px;
    position: fixed;
    left: -158px;
    top:40%;
    width: 200px;
    z-index:2;
  }
  .sticky-left li{
    list-style-type: none;
    background-color: none;
    color: #efefef;
    height: 43px;
    padding: 0px;
    margin: 0px 0px 1px 0px;
    -webkit-transition:all 0.25s ease-in-out;
    -moz-transition:all 0.25s ease-in-out;
    -o-transition:all 0.25s ease-in-out;
    transition:all 0.25s ease-in-out;
    cursor: pointer; 
  }
  .sticky-left li:hover{
    margin-right: -150px;
    background:#12375c;
    border-radius: 25px 25px 25px 0;
  }
  .sticky-left li img{
    float: right;
    border-radius:50%; 
    margin: 5px 5px;
    margin-left: 10px;
  }
  .sticky-left li p{
    padding: 0px;
    float:right;
    font-size: 15px;
    margin: 0px;
    text-transform: uppercase;
    line-height: 43px;
   
    color: #fff;
  }

  @media (max-width: 768px){
    .sticky-left li{
       margin-top: 60px;
      }
  }