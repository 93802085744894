.modal-head {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background:linear-gradient(to left, #4568dc, #b06ab3);;
  margin-top: 2rem;
  padding: 18px 41px;
  border-bottom: 1px solid #e9ecef;
 
  width: 100%;
}
.paysection{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.modal-text {
  border: none;
  font-size: 1.9rem;
  font-weight: bold;
  text-shadow: #bebbbe;
  background: none;
  /* animation: scrollTop 0.5s alternate ease infinite; */
  color: #fefefe;
}
.modal-text:before{
  content:"text";
  position:absolute;
  color:#262626;
  top:0;
  left:0;
  width:0%;
  overflow:hidden;
  transition:all 0.5s;
}
.modal-text:hover{
color: #12375c;
}


.payicon{
  font-size: 1.7rem;
  color: #f8f8f8;
  margin-right: 10px;
  margin-bottom: 6px;
  cursor: pointer;
  /* animation: scrollTop 0.5s 1s alternate ease infinite; */
}
.payicon:hover{
  color: #12375c;
}
.paysection:hover{
  color: #12375c;
}




@media (max-width: 768px) {
  .modal-head {
    padding: 6px 16px;
  }
  .modal-text {
    font-size: 1rem;
    font-weight: bold;
    text-shadow: #bebbbe;
    background: none;
  }
  
  .payicon{
    font-size: 1.9rem;
    color: #12375c;
    margin-right: 4px;
    cursor: pointer;
  }
}
