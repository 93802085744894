/* body {
  background: #B1EA86;
  padding: 30px 0
} */

a {
  text-decoration: none;
}

.pricingTable {
  text-align: center;
  background: #fff;
  margin: 0 -15px;
  box-shadow: 0 0 10px #ababab;
  padding-bottom: 40px;
  border-radius: 10px;
  color: #cad0de;
  transform: scale(1);
  transition: all .5s ease 0s
}

.pricingTable:hover {
  transform: scale(1.05);
  z-index: 1
}

.pricingTable .pricingTable-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 41px;
  background: #f5f6f9;
  /* border-radius: 10px 10px 50% 50%; */
  transition: all .5s ease 0s;
  border-radius: 11px 11px 0px 0px;
}
.price-container{
  /* width: 80%; */
/* margin-left: 150px !important; */

}

.pricingTable:hover .pricingTable-header {
  background: linear-gradient(to right, #12375c, #12375c) 
}

.pricingTable .pricingTable-header i {
  font-size: 50px;
  color: #858c9a;
  margin-bottom: 10px;
  transition: all .5s ease 0s
}
 .org-price{
  font-size: 20px;
  color: #858c9a ;
  transition: all .5s ease 0s
  
}

.pricingTable .price-value {
  font-weight: 900;
  font-size: 45px;
  color: #12375c ;
  transition: all .5s ease 0s
}

.pricingTable .month {
  display: block;
  font-size: 16px;
  color: #cad0de;
  font-weight: bold;
}

.pricingTable:hover .month,
.pricingTable:hover .price-value,
.pricingTable:hover .org-price,
.pricingTable:hover .pricingTable-header i {
  color: #fff
}

.pricingTable .heading {
  font-size: 20px;
  color: #e0dce0 ;
  margin-top: 5px;
  margin-bottom: 15px;
  text-transform: uppercase;
  background: #12375c;
  padding: 1rem;
}

.pricingTable .pricing-content ul {
 
/* padding-left: 10px; */
padding-right: 20px;
  margin-bottom: 30px;
  text-align: left;
  height: 373px;

}
.priceList-bga{
  background-color: #f5f5f7;
  background-blend-mode:darken;
}

.pricingTable .pricing-content ul li {
 
  /* line-height: 30px; */
  color: #3a3b3d;
  padding: 5px;


}
.pricingTable .pricingTable-signup{
  border: none;
}
.pricingTable .pricingTable-signup a {

  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 30px;
  border-radius:5px;
  transition: 0.5s;
  color: #fff;
 
  text-decoration: none;
  animation-delay: 0.6s;
  background-color: #12375c !important;
  font-family: 'Oswald', sans-serif;

  font-weight: 100;
  overflow: hidden;

  position: relative;
  text-decoration: none;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;

  opacity: 0.9 !important;
}
.pricingTable .pricingTable-signup a::after {
  color: #ffffff !important;
    background:linear-gradient(to right, #4568dc, #b06ab3);
    border-radius: 5px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  .pricingTable .pricingTable-signup a:hover::after {
    transform: translate(0, 0);
   
  }


/* .pricingTable .pricingTable-signup a:hover {
  color: #bb2685
} */

.pricingTable.blue .pricingTable-signup a,
.pricingTable.blue:hover .pricingTable-header {
  background: linear-gradient(to left, #4568dc, #b06ab3)
}

.pricingTable.blue .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #bb2685
}

.pricingTable.red .heading,
.pricingTable.red .price-value {
  color: #540553
}


.pricingTable.red .pricingTable-signup a,
.pricingTable.red:hover .pricingTable-header {
  background: #540553
}

.pricingTable.red .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #540553
}

.pricingTable.green .heading,
.pricingTable.green .price-value {
  color: #540553
}

.pricingTable.green .pricingTable-signup a,
.pricingTable.green:hover .pricingTable-header {
  background: #540553
}

.pricingTable.green .pricingTable-signup a:hover {
  box-shadow: 0 0 10px #540553
}

.pricingTable.blue:hover .price-value,
.pricingTable.green:hover .price-value,
.pricingTable.red:hover .price-value {
  color: #fff
}

.white-mode{
  font-size: 20px;
  float: right;
  font-weight: 400 !important;
}

.col-md-1 {
  flex: 0 0 auto;
  width: 4.333333%;
}

@media screen and (max-width:768px) {
  .pricingTable {
      margin: 0 10px 20px
  }
  .price-container{
    width: 80%;
  margin-left: 0px !important;
  
  }
}





@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Yesteryear&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@500&display=swap');
  
  .pricing .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
  
  .pricing hr {
    margin: 1.5rem 0;
  }
  
  .pricing .card-title {
    margin: 0.5rem 0;
    /* font-size: 0.9rem; */
    letter-spacing: .1rem;
    font-weight: bold;
    
    font-size: 18px !important;
    font-family: 'Oswald', sans-serif !important;
  }
  
  .pricing .card-price {
    font-size: 3rem;
    margin: 0;
  }
  
  .pricing .card-price .period {
    font-size: 0.8rem;
  }
  
  .pricing ul li {
    margin-bottom: 1rem;
  }
  
  .pricing .text-muted {
    opacity: 0.7;
  }
  
  .pricing .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s;
  }

  .price-title h5{
   color: aliceblue;
   font-weight: 600;
  }
  .card-head-bg{
    background: linear-gradient(to left, #4568dc, #b06ab3)  !important;
    padding-bottom: 3rem;
  }
.white-mode{
  padding-right: 5rem;
  padding-top: 1rem;
}
  
  /* Hover Effects on Card */

  @media (min-width: 992px) {
    .pricing .card:hover {
      margin-top: -.25rem;
      margin-bottom: .25rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
  
    .pricing .card:hover .btn {
      opacity: 1;
    }
  }
  @media (max-width: 768px) {

    .pricingTable .pricing-content ul {
 
      
        height: auto;
      
      }
}