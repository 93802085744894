@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Yesteryear&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200&display=swap');
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(to right, #4568dc, #b06ab3) !important;
  background-blend-mode: soft-light;
  background: transparent;
  opacity: 0.9;
}

.nav-item a:hover {
  /* color: rgb(221, 103, 103) !important; */
  border-radius: 5px;
  padding-top: 8px;
  text-decoration: line-through;

  transform: scaleX(1);
  transform-origin: right;
  transition: transform 0.5s;
}


.nav-link {
  font-family: 'Cairo', sans-serif;
  font-weight: bold !important;
  color: #f1f1f1 !important;
  margin-right: 10px;
  border-radius: 5px;
  transition: 0.5s;
  letter-spacing: 0.5px;
}

.nav-link::after{
  position: absolute;
  content: "";
  top: 100%;
  left: 0;
  width: 100%;
  height: 3px;
  background: #2e3560;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s;
}

/* .nav-link:hover{
  color: #95a5a6;
} */

.nav-link:hover::after{
  transform: scaleX(1);
  transform-origin: left;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #2e3560  !important;
  border-radius: 5px;
}

.navicon {
  color: #540553;
  margin-right: 0.5rem;
  animation: scrollRight 0.5s alternate ease infinite;
}
/* .navicon:hover {
  color: #fff;
} */

.megapackage{
  margin-left: -27px;

  cursor: pointer;
}
.megapackage:hover{
border: none !important;
}
@keyframes scrollRight {
  from {
    transform: translatex(2px);
  }
  to {
    transform: translatex(-1px);
  }
}

@media screen and (max-width: 768px){

  .navbar-brand img {
    width: 200px !important;
  }
  .navbar-light .navbar-brand {
    padding-left: 5rem;
  }

  .navbar-toggler{
    /* margin-right: 49px; */
    display: none;
  }
  /* .navbar-nav{
    margin-right: 80px;
  } */
  .nav-link {
    font-family: 'Cairo', sans-serif;
    font-weight: bold !important;
    color: #663678 !important;
    margin-right: 10px;
    border-radius: 5px;
    transition: 0.5s;
    letter-spacing: 0.5px;
  }
}