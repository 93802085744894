@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Yesteryear&display=swap');

/* .common{
    font-family: 'roboto', sans-serif !important;
    color: #2a2a2a !important;
    font-size: 3.5rem !important;
  
} */

.video-back{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    
    object-fit: cover;
    opacity: 100;
    z-index: -1;

}
.home-button{
  background-color: #12375c !important;
  font-family: 'Oswald', sans-serif;
  font-size: 4rem;
  font-weight: 100;
  overflow: hidden;
  padding: 1rem 2rem;
  position: relative;
  text-decoration: none;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;
  color: #ffffff !important;
  opacity: 0.9 !important;
}
.home-button::after {
  color: #ffffff !important;
    background:linear-gradient(to right, #4568dc, #b06ab3);
    border-radius: 5px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: 0.2s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  .home-button:hover::after {
    transform: translate(0, 0);
   
  }
  .home-button:hover {
  
    color: #ffffff !important;
    
  }
.slider{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    display: flex;
    align-items: center;
    /* justify-content: center; */
padding-left: 50px;
}
.slider::before {
    content: "";
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100vh;
    bottom: 0vh;
    left: 0;
    overflow: hidden;
    opacity: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.2) 50%,
      rgba(0, 0, 0, 0.2) 100%
    );
  }
  .liTitle{
    font-size: 20px;
    font-weight: bold !important;
    color: #eee8e8;
    list-style: disc;
  }

  .logoimgtwo{
    width: 300px;
    height: auto;
  }
  .logoimg{
    width: 80px;
    height: auto;
  }
.logosec{
  display: flex;
  align-items: flex-end;
  padding-top: 35rem;
  float: right;
  
}
  .logosec ul{
    
    float:right;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: #eeeaee;
    border-radius: 5px;
    margin: 5px;
    /* margin-right: 20px; */

  }

    
  .logosec li{
    cursor: pointer;
    list-style: none;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-right: 20px;
 
 }
 
  

@media screen and (max-width: 768px) {
    .video-back{
      width: 100%;
       
        object-fit: cover;
    }
    .home-content{
      margin-top: 0;
      margin-top: 100px !important;
    
    }
    #header h1{

      font-size: 15px;
      width: 100%;
     
     /* margin-left: 13px; */
     letter-spacing: 0;
 
     line-height: 2;
    }

    #header h2 {
      margin-right: 0;
      font-size: 15px;
      /* margin-left: 10px; */
    }
    .home-button{
      font-size: 12px !important;
      /* margin-left: 13px; */
      margin-top: 12px;
  
      
    }
 

    .slider{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
     
      display: flex;
      align-items: center;
      justify-content: start;
  }
    .liTitle{
      font-size: 30px;
      font-weight: bold !important;
      color: #eee8e8;
      list-style: disc;
    }
  .logosec{
    margin-top: 164px;
    padding-top: 0px;
    margin-bottom: 27px;
  }
  .logosec ul {
    width: 147px;

  }
    .logoimgtwo{
      width: 30px;
      height: auto;
    }
    .logoimg{
      width: 30px;
      height: auto;
    }
  
}